import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const ContactWrapper = styled.div`
    width: 500px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 16px;
    margin: 16px 0;
`;

const Title = styled.h3`
    font-size: 24px;
    letter-spacing: 4px;
`;

const Description = styled.p`
    font-size: 18px;
`;

const Contact = ({ description, title }) => (
    <ContactWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
    </ContactWrapper>
);

Contact.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string,
};

export default Contact;
