import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import config from '../../config/config';
import Contact from './Contact';

const ContactSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 64px;
`;

const List = styled.div`
    max-width: 100%;
`;

const Separator = styled.hr`
    height: 1px;
    width: 200px;
    background: #aaa;
    border: none;
`;

const ContactList = () => {
    const {
        contact: { main, list },
    } = config;

    return (
        <ContactSection>
            <Contact description={main.description} title={main.title} />
            <List>
                {list.map((contact) => (
                    <Fragment key={contact.title}>
                        <Separator />
                        <Contact description={contact.description} title={contact.title} />
                    </Fragment>
                ))}
            </List>
        </ContactSection>
    );
};

export default ContactList;
