import React from 'react';
import Layout from '../components/layout/Layout';
import ContactList from '../components/contact/ContactList';

const ContactPage = () => (
    <Layout>
        <ContactList />
    </Layout>
);

export default ContactPage;
